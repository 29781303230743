import React, { ReactNode } from "react"
import {
  VStack,
  chakra,
  SimpleGrid,
  GridItem,
  Button,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import { Section } from "components"

interface IProps {
  children: ReactNode
}

const Award = ({ children }: IProps) => {
  const sectionBgColor = useColorModeValue("gray.50", "gray.700")
  const headingAccentColor = useColorModeValue("primary.500", "primary.200")
  const descriptionColor = useColorModeValue("gray.600", "gray.300")

  return (
    <Section maxW="100%" background={sectionBgColor}>
      <SimpleGrid
        maxW="1200px"
        m="0 auto"
        py={{ base: 12, lg: 16 }}
        mt={{ base: 16, lg: 24 }}
        columns={4}
        spacingX="70px"
      >
        <GridItem
          as={VStack}
          spacing={4}
          align={{ base: "center", md: "flex-start" }}
          textAlign={{ base: "center", md: "start" }}
          colSpan={{ base: 4, lg: 3 }}
        >
          <chakra.h2
            fontSize={{ base: "md", md: "2xl" }}
            fontWeight="800"
            lineHeight="shorter"
          >
            Platforma{" "}
            <chakra.span color={headingAccentColor}>SIDAS PZP</chakra.span>{" "}
            produktem roku 2021!
          </chakra.h2>
          <chakra.p color={descriptionColor}>
            Platforma SIDAS PZP zajęła I miejsce w kategorii „Oprogramowanie
            dedykowane administracji publicznej” w plebiscycie miesięcznika IT w
            Administracji.
          </chakra.p>
          <Button
            colorScheme="primary"
            variant="ghost"
            as={chakra.a}
            rightIcon={<ArrowForwardIcon />}
            href="https://itwadministracji.pl/2021/12/01/produkt-roku-2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dowiedz się więcej
          </Button>
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 1 }}>
          <HStack pt={{ base: 8, lg: 0 }} justify="center">
            {children}
          </HStack>
        </GridItem>
      </SimpleGrid>
    </Section>
  )
}

export { Award }
