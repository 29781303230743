import React from "react"
import { VStack, chakra, useColorModeValue, Button } from "@chakra-ui/react"

import { Section } from "components"
import { ArrowForwardIcon } from "@chakra-ui/icons"

const About = () => {
  const headingAccentColor = useColorModeValue("primary.500", "primary.200")

  return (
    <VStack as={Section} align="flex-start" spacing={6}>
      <chakra.header>
        <chakra.h2
          fontSize={{ base: "xl", md: "4xl" }}
          fontWeight="800"
          lineHeight="shorter"
        >
          Czym jest{" "}
          <chakra.span color={headingAccentColor}>SIDAS PZP.</chakra.span>
        </chakra.h2>
      </chakra.header>
      <VStack spacing={8} align="flex-start">
        <VStack spacing={4} align="flex-start">
          <chakra.p>
            <strong>
              SIDAS PZP jako system do realizacji zamówień publicznych w ramach
              projektu e-Zamówienia
            </strong>
            , daje odpowiednie narzędzia, zarówno po stronie Wykonawcy w
            zakresie składania ofert, prowadzenia dialogu z Zamawiającym oraz
            śledzenia i monitorowania przetargów, a także umożliwia działom
            zamówień publicznych skuteczną realizację ich zadań.
          </chakra.p>
          <chakra.p>
            Założeniem systemu <strong>SIDAS PZP</strong> jest dostarczyć
            kompleksowy system do realizacji zamówień publicznych, który
            upraszcza łańcuch dostaw i odpowiada za pełną obsługę procesów
            zakupowych za pośrednictwem Internetu w ramach obecnie realizowane
            projektu e-Zamówienia.
          </chakra.p>
          <chakra.p>
            Projekt zakłada udostępnienie szeregu funkcji i udogodnień dla
            użytkownika, które będą obejmować automatyzację wewnętrznych
            procesów zamówień, integrację z innymi systemami z grupy{" "}
            <strong>SIDAS (EZD, BUDŻET, BIP)</strong>, system do automatycznej
            weryfikacji podpisów elektronicznych oraz proces elektronicznego
            składania wniosków, który wykorzystuje aukcje i licytacje online
            celem zgromadzenia jak największej ilości ofert na potrzeby
            określonego przetargu.
          </chakra.p>
        </VStack>
        <VStack spacing={4} align="flex-start">
          <chakra.b>
            Zgłoś się do nas w sprawie oferty lub umów się na prezentację online
            naszej platformy do zamówień publicznych SIDAS PZP.
          </chakra.b>
          <Button
            colorScheme="primary"
            as={chakra.a}
            rightIcon={<ArrowForwardIcon />}
            href="https://zamowienia.sidaspzp.pl/kontakt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Skontaktuj się z nami
          </Button>
        </VStack>
      </VStack>
    </VStack>
  )
}

export { About }
