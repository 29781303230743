import React from "react"
import { Icon, Layout, Section, Seo } from "components"
import { Box, Button, chakra, Flex, useColorModeValue } from "@chakra-ui/react"
import { mdiTextBoxCheckOutline } from "@mdi/js"
import { useDownloadInstruction } from "modules/home"

const Instructions = () => {
  const descriptionColor = useColorModeValue("gray.500", "gray.200")

  const download = useDownloadInstruction()

  return (
    <Layout>
      <Seo
        keywords={["platforma zamówień publicznych - instrukcje", "Madkom SA"]}
      />
      <chakra.div
        as={Section}
        mt={{ base: 28, md: 40, lg: 56 }}
        mb={{ base: 28, md: 40 }}
      >
        <Flex mx="auto">
          <Box mx="auto" w="600px" textAlign={{ base: "center", md: "start" }}>
            <chakra.h1
              mb={3}
              fontSize={{
                base: "2xl",
                md: "4xl",
              }}
              fontWeight="bold"
            >
              Instrukcja PZP dla wykonawcy
            </chakra.h1>
            <chakra.p
              mb={5}
              color={descriptionColor}
              fontSize={{
                md: "lg",
              }}
            >
              Dowiedz się w jaki sposób możesz skorzystać z naszej platformy
              jako wykonawca. Kliknij przycisk poniżej aby pobrać najnowszą
              instrukcję panelu wykonawcy.
            </chakra.p>
            <Button
              variant="solid"
              colorScheme="primary"
              size="lg"
              onClick={download}
              leftIcon={<Icon path={mdiTextBoxCheckOutline} />}
            >
              Pobierz instrukcję
            </Button>
          </Box>
        </Flex>
      </chakra.div>
    </Layout>
  )
}

export default Instructions
