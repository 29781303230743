import React from "react"
import { VStack, chakra, useColorModeValue } from "@chakra-ui/react"

import { Section } from "components"

const About2 = () => {
  const headingAccentColor = useColorModeValue("primary.500", "primary.200")
  const sectionBgColor = useColorModeValue("gray.50", "gray.700")

  return (
    <Section maxW="100%" background={sectionBgColor}>
      <VStack
        maxW="1200px"
        m="0 auto"
        py={{ base: 12, lg: 20 }}
        align="flex-start"
        spacing={6}
      >
        <chakra.header>
          <chakra.h2
            fontSize={{ base: "xl", md: "4xl" }}
            fontWeight="800"
            lineHeight="shorter"
            color={headingAccentColor}
          >
            SIDAS PZP.
          </chakra.h2>
        </chakra.header>
        <VStack spacing={8} align="flex-start">
          <VStack spacing={4} align="flex-start">
            <chakra.p>
              <b>
                SIDAS PZP to system obsługujący przetargi za pośrednictwem
                platformy internetowej.
              </b>{" "}
              Jest to pełnoprawne narzędzie mające na celu poprawę
              przejrzystości i efektywności procesu zakupów oraz generowanie
              oszczędności dla Zamawiających.
            </chakra.p>
            <chakra.p>
              Wydziały ds. Zamówień znajdują się w większości organizacji. Są
              odpowiedzialne za proces realizacji zakupu surowców, materiałów
              biurowych, utrzymanie obiektu, projektów budowlanych, systemów
              informatycznych i innych. Ważne jest, aby w procesie realizacji
              zamówienia był wykorzystywany odpowiedni system wykorzystujący
              <b> założenia projektu e-Zamówienia.</b>
            </chakra.p>
          </VStack>
        </VStack>
      </VStack>
    </Section>
  )
}

export { About2 }
