import React from "react"

import { Section } from "components"
import {
  useColorModeValue,
  VStack,
  chakra,
  Button,
  Flex,
  Box,
  Stack,
  Center,
  useBreakpointValue,
  ButtonGroup,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import {
  mdiExcavator,
  mdiOfficeBuilding,
  mdiTextBoxCheckOutline,
} from "@mdi/js"

import { Icon } from "components/Icon"
import { useDownloadInstruction } from "./useDownloadInstruction"

const Signup = () => {
  const sectionBgColor = useColorModeValue("gray.50", "gray.700")
  const cardBackground = useColorModeValue("white", "gray.600")
  const cardTitleColor = useColorModeValue("gray.900", "white")
  const cardDescriptionColor = useColorModeValue("gray.600", "gray.200")
  const iconBorderColor = useColorModeValue("primary.500", "primary.300")
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" })
  const descriptionColor = useColorModeValue("gray.600", "gray.300")

  const download = useDownloadInstruction()

  return (
    <Section
      maxW="100%"
      background={sectionBgColor}
      mt={{ base: 12, lg: 24 }}
      id="signup-section"
    >
      <VStack
        maxW="1200px"
        align="center"
        m="0 auto"
        pt={{ base: 12, lg: 20 }}
        pb={{ base: 2, lg: 12 }}
        spacing={4}
      >
        <chakra.header>
          <chakra.h2
            fontSize={{ base: "xl", md: "4xl" }}
            fontWeight="800"
            lineHeight="shorter"
            textAlign="center"
          >
            Elektronizacja zamówień publicznych.
          </chakra.h2>
        </chakra.header>
        <chakra.p
          fontSize={{ md: "md", lg: "xl" }}
          textAlign="center"
          color={descriptionColor}
        >
          Zarejestruj się w systemie SIDAS PZP jako Zamawiający i skorzystaj z
          promocyjnej oferty.
        </chakra.p>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 0, lg: 2 }}
          pt={6}
        >
          <Flex p={50} w="full" alignItems="center" justifyContent="center">
            <Box
              py={4}
              px={{ base: 6, md: 8 }}
              minW="300px"
              bg={cardBackground}
              shadow="lg"
              rounded="lg"
              h="100%"
            >
              <Flex justifyContent={{ base: "center", md: "end" }} mt={-12}>
                <chakra.div
                  w={16}
                  h={16}
                  fit="cover"
                  rounded="full"
                  borderStyle="solid"
                  borderWidth={3}
                  borderColor={iconBorderColor}
                  bg={cardBackground}
                >
                  <Center h="100%">
                    <Icon
                      size="8"
                      color={iconBorderColor}
                      path={mdiExcavator}
                    />
                  </Center>
                </chakra.div>
              </Flex>
              <chakra.h3
                color={useColorModeValue("gray.800", "white")}
                fontSize={{ base: "lg", md: "2xl" }}
                mt={{ base: 2, md: 0 }}
                fontWeight="bold"
              >
                Wykonawca
              </chakra.h3>
              <chakra.p
                mt={2}
                fontSize={{ base: "sm", md: "md" }}
                color={cardDescriptionColor}
              >
                Wejdź w posiadanie odpowiednich narzędzi z zakresu automatyzacji
                składania ofert, prowadzenia dialogu z Zamawiającym oraz śledź i
                monitoruj przetargi w których bierzesz bądź planujesz wziąć
                udział.
              </chakra.p>
              <Flex justifyContent="end" mt={4}>
                <ButtonGroup as={VStack} align="end">
                  <Button
                    size={buttonSize}
                    colorScheme="primary"
                    variant="ghost"
                    as={chakra.a}
                    onClick={download}
                    rightIcon={<Icon path={mdiTextBoxCheckOutline} />}
                    cursor="pointer"
                  >
                    Pobierz instrukcję
                  </Button>
                  <Button
                    size={buttonSize}
                    colorScheme="primary"
                    variant="ghost"
                    as={chakra.a}
                    rightIcon={<ArrowForwardIcon />}
                    href="https://app.sidaspzp.pl/rejestracja/wykonawca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dołącz jako wykonawca
                  </Button>
                </ButtonGroup>
              </Flex>
            </Box>
          </Flex>
          <Flex p={50} w="full" alignItems="center" justifyContent="center">
            <Box
              py={4}
              px={{ base: 6, md: 8 }}
              minW="300px"
              bg={cardBackground}
              shadow="lg"
              rounded="lg"
              h="100%"
            >
              <Flex justifyContent={{ base: "center", md: "end" }} mt={-12}>
                <chakra.div
                  w={16}
                  h={16}
                  fit="cover"
                  rounded="full"
                  borderStyle="solid"
                  borderWidth={3}
                  borderColor={iconBorderColor}
                  bg={cardBackground}
                >
                  <Center h="100%">
                    <Icon
                      size="8"
                      color={iconBorderColor}
                      path={mdiOfficeBuilding}
                    />
                  </Center>
                </chakra.div>
              </Flex>
              <VStack
                align="stretch"
                justifyContent="space-between"
                h="calc(100% - 1em)"
              >
                <div>
                  <chakra.h3
                    color={cardTitleColor}
                    fontSize={{ base: "lg", md: "2xl" }}
                    mt={{ base: 2, md: 0 }}
                    fontWeight="bold"
                  >
                    Zamawiający
                  </chakra.h3>
                  <chakra.p
                    mt={2}
                    fontSize={{ base: "sm", md: "md" }}
                    color={cardDescriptionColor}
                  >
                    Uzyskaj dostęp do kompleksowego systemu obsługi zamówień
                    publicznych, który upraszcza proces organizacji oraz
                    odpowiada za pełną obsługę procesów zamówień publicznych za
                    pośrednictwem Internetu.
                  </chakra.p>
                </div>
                <Flex justifyContent="end" mt={4}>
                  <Button
                    size={buttonSize}
                    colorScheme="primary"
                    variant="ghost"
                    as={chakra.a}
                    rightIcon={<ArrowForwardIcon />}
                    href="https://app.sidaspzp.pl/rejestracja/zamawiajacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dołącz jako zamawiający
                  </Button>
                </Flex>
              </VStack>
            </Box>
          </Flex>
        </Stack>
      </VStack>
    </Section>
  )
}

export { Signup }
