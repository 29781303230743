import React from "react"
import {
  SimpleGrid,
  useColorModeValue,
  chakra,
  GridItem,
  VStack,
  Button,
  Stack,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import { Section } from "components"

const Hero = () => {
  const headingAccentColor = useColorModeValue("primary.500", "primary.200")
  const descriptionColor = useColorModeValue("gray.700", "gray.300")

  return (
    <SimpleGrid
      as={Section}
      columns={3}
      spacingX="70px"
      spacingY={{ base: "60px", md: "90px" }}
      mt={{ base: 28, md: 40, lg: 56 }}
      pb={{ base: 2, md: 16, lg: 32 }}
    >
      <GridItem colSpan={{ base: 3, lg: 3 }}>
        <chakra.header
          as={VStack}
          textAlign={{ base: "center", md: "start" }}
          align={{ base: "center", md: "flex-start" }}
          spacing={8}
        >
          <chakra.h1
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="800"
            lineHeight="shorter"
          >
            <chakra.span display="block">SIDAS PZP</chakra.span>
            <chakra.span
              display="block"
              color={headingAccentColor}
              fontSize={{ base: "2xl", md: "4xl" }}
            >
              Platforma Zamówień Publicznych
            </chakra.span>
          </chakra.h1>
          <chakra.p
            mb={6}
            fontSize={{ base: "md", md: "lg", lg: "xl" }}
            color={descriptionColor}
          >
            Założeniem systemu SIDAS PZP jest dostarczenie kompleksowego systemu
            do realizacji zamówień publicznych za pośrednictwem Internetu w
            ramach
            <strong> obecnie obowiązującej elektronizacji zamówień</strong>.
          </chakra.p>
          <Stack
            w="100%"
            direction={{ base: "column", md: "row" }}
            spacing={3}
            pt={3}
            align={{ base: "center", md: "flex-start" }}
          >
            <Button
              size="lg"
              colorScheme="primary"
              as={chakra.a}
              href="https://zamowienia.sidaspzp.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Platforma PZP
            </Button>
            <Button
              size="lg"
              colorScheme="primary"
              variant="ghost"
              as={chakra.a}
              rightIcon={<ArrowForwardIcon />}
              href="https://zamowienia.sidaspzp.pl/kontakt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kontakt
            </Button>
          </Stack>
        </chakra.header>
      </GridItem>
    </SimpleGrid>
  )
}

export { Hero }
