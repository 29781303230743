import React, { ReactNode } from "react"
import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  chakra,
  VStack,
} from "@chakra-ui/react"
import {
  mdiFlashOutline,
  mdiEyeCheckOutline,
  mdiFileCertificateOutline,
  mdiPoll,
} from "@mdi/js"

import { Section, Icon } from "components"

const Features = () => {
  const iconColor = useColorModeValue("primary.600", "primary.200")
  const headingAccentColor = useColorModeValue("primary.500", "primary.200")
  const descriptionColor = useColorModeValue("gray.600", "gray.300")
  const linkColor = useColorModeValue("blue.600", "blue.300")

  const Feature = (props: {
    children: ReactNode
    path: string
    title: string
  }) => {
    return (
      <Flex direction={{ base: "column", md: "row" }}>
        <Flex shrink={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            h={12}
            w={12}
            rounded="md"
          >
            <Icon size="7" color={iconColor} path={props.path} />
          </Flex>
        </Flex>
        <Box ml={4}>
          <chakra.h3
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="medium"
            lineHeight="6"
          >
            {props.title}
          </chakra.h3>
          <chakra.p
            mt={2}
            color={descriptionColor}
            fontWeight="400"
            fontSize={{ base: "sm", md: "md" }}
          >
            {props.children}
          </chakra.p>
        </Box>
      </Flex>
    )
  }

  return (
    <Section as={VStack} spacing={10} mt={{ base: 14, lg: 24 }}>
      <chakra.header textAlign="center">
        <chakra.h2
          fontSize={{ base: "xl", md: "4xl" }}
          fontWeight="800"
          lineHeight="shorter"
        >
          Dlaczego warto wybrać{" "}
          <chakra.span color={headingAccentColor}>SIDAS PZP.</chakra.span>
        </chakra.h2>
        <chakra.p
          mt={4}
          maxW="4xl"
          fontSize={{ md: "md", lg: "xl" }}
          mx={{ lg: "auto" }}
          color={descriptionColor}
        >
          SIDAS PZP pozwoli na przeprowadzenie postępowania zgodnie z ustawą PZP
          zarówno dla Zamawiających z administracji publicznej jak i wszystkich
          Wykonawców.
        </chakra.p>
      </chakra.header>
      <Box mt={10}>
        <Stack
          spacing={{ base: 10, md: 0 }}
          display={{ md: "grid" }}
          gridTemplateColumns={{ md: "repeat(2,1fr)" }}
          gridColumnGap={{ md: 8 }}
          gridRowGap={{ md: 10 }}
        >
          <Feature title="Elektronizacja procesu" path={mdiFlashOutline}>
            SIDAS PZP to narzędzie, zaprojektowane od podstaw z myślą o
            elektronizacji procesu zamówień publicznych – zgodne z projektem
            e-Zamówienia.
          </Feature>
          <Feature
            title=" Przejrzystość i efektywność"
            path={mdiEyeCheckOutline}
          >
            SIDAS PZP jest narzędziem mającym na celu poprawę przejrzystości i
            efektywności procesu zakupów oraz generowanie oszczędności dla
            Zamawiających.
          </Feature>
          <Feature title="Wydajność i oszczędność" path={mdiPoll}>
            SIDAS PZP sprawia, że proces zakupowy jest dużo wydajniejszy;
            kupujący oszczędzają czas, a kierownictwo może łatwo uzyskać dostęp
            do danych i raportów.
          </Feature>
          <Feature
            title="Produkt certyfikowany"
            path={mdiFileCertificateOutline}
          >
            SIDAS PZP jest narzędziem certyfikowanym przez SIMAP i znajduje się
            na liście certyfikowanych partnerów TED{" "}
            <chakra.a
              href="https://simap.ted.europa.eu/pl/list-of-ted-esenders"
              target="_blank"
              rel="noopener noreferrer"
              color={linkColor}
            >
              eSENDERS
            </chakra.a>
            .
          </Feature>
        </Stack>
      </Box>
    </Section>
  )
}

export { Features }
