// @ts-ignore
import executor_instruction from "../../assets/executor_instruction.pdf?url"

export const useDownloadInstruction = () => {
  return () => {
    const anchor = document.createElement("a")
    anchor.href = executor_instruction
    anchor.download = "instrukcja_wykonawcy"

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }
}
